import type { RoofPart } from '@/calculation/roofpart';
import { addResults, type ResultRow, type TotalTiles } from '@/calculation/result';
import type { Extras } from '@/project';

import { addResult, removeZeroQtyRows } from '@/calculation/result';
import { Category } from '@/calculation/result';
import { RoofTile, RoofTileSelection } from '@/calculation/rooftile';
import { addArticleSum, ArticleProductGroups, MarketStr, RoofArticle, svinn, TileFamily, Underlagstak, NockPannaVariant, GavelTillval, isCompatMode, round, RoofType, RoofForm } from '@/calculation/common';
import type { SAFECalc } from './safecalc';


/**
 * Represents the complete roof of a single building, including all extensions.
 */
export class Roof {

	public name: string;

	public market: MarketStr;

	public amount = 1;

	public mainPart: RoofPart | null = null;
	public extensions: RoofPart[] = [];

	public safe: SAFECalc | null = null;

	public selection: RoofTileSelection;

	private useFastLäktavstånd = false;
	private fastLäktavstånd = 0;

	public underlagstak = Underlagstak.None;

	constructor(name = "", market = MarketStr.Sverige) {
		this.name = name;
		this.selection = new RoofTileSelection(this);
		this.market = market;
	}

	public forEachRoofPart(callback: (roofPart: RoofPart) => void) {
		if (this.mainPart !== null) callback(this.mainPart);

		for (const ext of this.extensions)
			callback(ext);
	}

	public setLäktavstånd(fast: boolean, avstånd?: number) {
		if (!fast && avstånd !== undefined) console.error('Passing argument avstånd when using computed läktavstånd has no effect');
		this.useFastLäktavstånd = fast;
		if (fast && avstånd !== undefined)
			this.fastLäktavstånd = avstånd;
	}

	public getLäktavstånd(): number | null {
		if (this.useFastLäktavstånd) return this.fastLäktavstånd;
		return null;
	}

	public usesFastLäktavstånd(): boolean {
		return this.useFastLäktavstånd;
	}

	public getAntalTaksteg(): number {
		if (this.mainPart === null) throw Error('No main roof part set');

		let pannor = round(this.mainPart.calculatePannrader().antalRader);
		pannor -= 1; // Remove a row to get the correct number of steps
		return pannor;
	}

	/** If this roof needs to use the large gable tile for article families that have one */
	public usesBredGavelpanna() {
		if ([RoofTile.Palema, RoofTile.Exklusiv].includes(this.selection.tileFamily))
			for (const part of [this.mainPart, ...this.extensions])
				if (part?.usesBredGavelpanna?.()) return true;

		return false;
	}

	/** Returnerar antalet hörnvinkeldelar på taket */
	public numHVTak(): number {
		let hv = 0;
		for (const part of this.extensions)
			if (part.type === RoofType.HörnVinkel) hv += part.amount;
		return hv;
	}

	/** Om taket innehåller minst en hörnvinkeldel */
	public isHVTak() {
		return this.extensions.find(part => part.type === RoofType.HörnVinkel) !== undefined;
	}

	public calculateTakfotslängder() {
		let längder: number[] = [];
		for (const part of [this.mainPart, ...this.extensions]) {
			if (!part) continue;
			const extLängder = part.getTakfotslängder();
			for (let i = 0; i < part.getAbsAmount(); i++)
				längder = längder.concat(extLängder);
		}
		return längder;
	}

	public sumBärläktAvstånd(includeSvinn = false) {
		let bärläktAvst = 0;
		this.forEachRoofPart(part => bärläktAvst += part.calculateBärläkt(false) * part.getAbsAmount());
		if (includeSvinn) bärläktAvst *= svinn.bärläkt;
		return bärläktAvst;
	}

	public sumStröläkt(includeSvinn = false) {
		let ströläkt = 0;
		this.forEachRoofPart(part => ströläkt += part.calculateStröläkt(false) * part.getAbsAmount());
		if (includeSvinn) ströläkt *= svinn.ströläkt;
		return ströläkt;
	}

	public sumRoofArea() {
		let roofArea = 0;
		this.forEachRoofPart(part => {
			const area = part.getRoofArea();
			roofArea += (area.area + area.area2) * part.getAbsAmount();
		});
		return roofArea;
	}

	public sumBTSUndertak() {
		let kpop = 0;
		this.forEachRoofPart(part => {
			const area = part.getRoofArea();
			kpop += (area.area + area.area2) * part.getAbsAmount() / 1000**2 / 22.5;
		});
		return Math.ceil(kpop);
	}

	public sumNocklängd() {
		let nocklängd = 0;
		this.forEachRoofPart(part => nocklängd += part.getNocklängd().nocklängd * part.getAbsAmount());
		return nocklängd;
	}

	public sumPultlängd() {
		let pultlängd = 0;
		this.forEachRoofPart(part => pultlängd += part.getNocklängd().pultlängd * part.getAbsAmount());
		return pultlängd;
	}

	public sumVindskivelängd() {
		if (this.mainPart === null) throw Error('No main roof part set');

		let vindskivelängd = 0;
		for (const part of [this.mainPart, ...this.extensions])
			vindskivelängd += part.getVindskivelängd() * part.sides * part.getGavlar() * part.getAbsAmount();
		return vindskivelängd;
	}

	public sumNockbenslängd() {
		if (this.mainPart == null) return 0;
		let total = 0;
		for (const part of [this.mainPart, ...this.extensions])
			total += part.getNockbenLängd().totalNockbenLängd * part.getAbsAmount();
		return total;
	}

	public sumTakfotslängd() {
		let takfot = 0;
		this.forEachRoofPart(part => takfot += part.getTakfotslängd() * part.getAbsAmount());
		return takfot;
	}

	public sumRänndalslängd() {
		if (this.mainPart == null) return 0;
		let ränndaler = 0;
		for (const part of [this.mainPart, ...this.extensions])
			ränndaler += part.getRändalslängd() * part.rändaler * part.getAbsAmount();
		return ränndaler;
	}

	public sumRänndalstätning() {
		if (this.mainPart == null) return 0;
		let total = 0;
		for (const part of [this.mainPart, ...this.extensions])
			total += part.calculateRändalstätning() * part.getAbsAmount();
		return total;
	}

	public sumVentinock() {
		if (this.mainPart == null) return 0;
		let venti = 0;
		for (const part of [this.mainPart, ...this.extensions])
			venti += part.getVentiNock() * part.getAbsAmount();
		return Math.ceil(venti);
	}

	public sumNockOchValmtätningsrulle() {
		if (this.mainPart == null) return 0;
		let rulle = 0;
		for (const part of [this.mainPart, ...this.extensions])
			rulle += part.calculateNockOchValmtätningsrulle() * part.getAbsAmount();
		return rulle;
	}

	// Ventinock använder fortfarande valmtätningsrulle för nockbenen på valmade tak (rad 186), beräknas här
	public sumNockOchValmtätningsrulleNockben() {
		if (this.mainPart == null) return 0;
		let rulle = 0;
		for (const part of [this.mainPart, ...this.extensions])
			rulle += part.getNockbenLängd().totalNockbenLängd * part.getAbsAmount();
		return rulle;
	}

	public sumNockbrädshållare() {
		if (this.mainPart == null) return 0;
		let nbh = 0;
		for (const part of [this.mainPart, ...this.extensions])
			nbh += part.calculateNockbrädshållare() * part.getAbsAmount();
		return nbh;
	}

	public async sumSealant(): Promise<ResultRow[]> {
		const artno = this.selection.selectedSealantArtNo;
		let rows: ResultRow[];
		switch (artno.substring(0, artno.length - 2)) {
		case '0924':
		case '0916':
		case '0907':
			return [{ art: RoofArticle.None, artnr: this.selection.selectedSealantArtNo, total: Math.ceil(this.sumNockOchValmtätningsrulle() / 5000), category: Category.Tillbehör, origins: {} }];
		case '0902':
			rows = [{ art: RoofArticle.None, artnr: this.selection.selectedSealantArtNo, total: this.sumVentinock(), category: Category.Tillbehör, origins: {} }];

			// Rad 186, Beräkna valmtätning för nockbenen på valmade tak även när Ventinock är valt
			await this.addArticleRow(rows, RoofArticle.Valmtätning300, Math.ceil(this.sumNockOchValmtätningsrulleNockben() / 5000), Category.Tillbehör, true);

			return rows;
		default:
			console.warn("Couldn't reverse match chosen sealant in Roof.sumSealant, no sealant accessory added");
			return [];
		}
	}

	public sumVentilation() {
		switch (this.selection.selectedVentilationArtNo) {
		case this.selection.tileFamily.getArticle(RoofArticle.Fågelband):
			return Math.ceil(this.sumTakfotslängd() / 1000);
		case this.selection.tileFamily.getArticle(RoofArticle.Fågelband5mSvart):
			return Math.ceil(this.sumTakfotslängd() / 5000);
		case this.selection.tileFamily.getArticle(RoofArticle.Fågelband5mRöd):
			return Math.ceil(this.sumTakfotslängd() / 5000);
		case this.selection.tileFamily.getArticle(RoofArticle.VentiFågelband):
			return Math.ceil(this.sumTakfotslängd() / 1000);
		default:
			console.error("Couldn't reverse match chosen ventilation in Roof.sumVentilation, no ventilation accessory added");
			return 0;
		}
	}

	public sumFixingTile(calc: {[key: string]: number}) {
		if (!this.selection.selectedFixingTileArtNo) return 0;

		const artno = this.selection.selectedFixingTileArtNo;
		switch (artno.substring(0, artno.length - 2)) {
		case '0944': // Palema skruvpanna
			return Math.ceil(calc[RoofArticle.Fästelement] / 100);
		case '0954': // Carisma skruvpanna
			// Used to add 2*Gavelbeslag worth of screws, why? Have added Gavelbeslag to Fästelement count so they'll affecct all fästelement
			// return Math.ceil((calc[RoofArticle.GavelBeslag] * 2 + calc[RoofArticle.Fästelement]) * svinn.fästelement / 200);
			return Math.ceil(calc[RoofArticle.Fästelement] / 200);
		case '0964':
		case '0965':
		case '0966':
		case '0942': // Rostfri spik
			return Math.ceil(calc[RoofArticle.Fästelement] / 250);
		case '0967': // Easy clip
			return Math.ceil(calc[RoofArticle.Fästelement] / 250);
		case '0962': // Palema stormclips
		case 'C960': // Carisma stormclips
			return Math.ceil(calc[RoofArticle.Fästelement] / 2 / 125);
		case '0961': // Exklusiv stormclips
			return Math.ceil(calc[RoofArticle.Fästelement] / 2 / 100);
		case 'L961': // Diagonalclip Zial
		case 'L962': // Diagonalclip
		case 'H963': // Piano Stormclips
		case 'H964': // Hansa sidofallsklammer
		case 'H965': // Tvilling sidofallsklammer
			return Math.ceil(calc[RoofArticle.Fästelement] / 2 / 250);
		case 'L940': // SKlammer
			return Math.ceil(calc[RoofArticle.Fästelement] / 2 / 100);
		case '0939': // Takkrok JP
			return this.roundUpArticle(calc[RoofArticle.Fästelement] / 2 / 250, svinn.fästelement);
		case '0940': // Takpannekrok
			return this.roundUpArticle(calc[RoofArticle.Fästelement] / 100, svinn.fästelement);
		default:
			console.error("Couldn't reverse match chosen fixing in Roof.sumFixingTile, no tilefixings added");
			return 0;
		}
	}

	public sumFixingNock(calc: {[key: string]: number}, selected: string) {
		if (!selected) return 0;

		switch (selected.substring(0, selected.length - 2)) {
		case '0936':
			return this.roundUpArticle(calc[RoofArticle.Nockskruv] / 100, svinn.nockskruv);
		case '0943':
		case '0946':
			return this.roundUpArticle(calc[RoofArticle.Nockskruv] / 50, svinn.nockskruv);
		case '1147':
		case 'H1147':
		case 'H1247':
			return this.roundUpArticle(calc[RoofArticle.Nockskruv], svinn.nockklämma);
		default:
			console.error("Couldn't reverse match chosen fixing in Roof.sumFixingNock, no nockfixings added", selected, this.selection.tileFamily.itemNos);
			return 0;
		}
	}

	public sumStormclips(takpannor: number, halvpannor: number, gavelpannaVänster = 0, gavelpannaHöger = 0) {
		if (this.mainPart == null) return 0;
		takpannor = takpannor || 0;
		halvpannor = halvpannor || 0;
		gavelpannaVänster = gavelpannaVänster || 0;
		gavelpannaHöger = gavelpannaHöger || 0;

		let stormclips = 0;
		for (const part of [this.mainPart, ...this.extensions])
			stormclips += part.calculateStormclips(takpannor, halvpannor, gavelpannaVänster, gavelpannaHöger) * part.getAbsAmount();
		return stormclips;
	}

	/** Beräknar antalet pallar för takpannorna och takpannetillbehören */
	// Paths:
	// TC1	For
	// TC1		case PG_TAKPANNOR
	// TC1		case PG_BT_LT_TILLBEHOR
	// TC1		case PG_TAKSAKERHET, PG_VENT_O_GEN, PG_INFAST, PG_TAT_LUFT, PG_OVRIGA_TILLB
	// TC3	If tillbehör > 0.25
	public async calculatePallets(articles: ResultRow[]): Promise<number> {
		let tilePallets = 0;
		let accPallets = 0;
		for (const art of articles) {
			if (art.artnr === '999999') continue; // Skip rows we couldn't find artnrs for

			if (ArticleProductGroups[art.art] === '0') // Roof tiles
				tilePallets += (art.total / await this.selection.getPalletAmount(art.artnr)) || 0;
			else if (ArticleProductGroups[art.art] === '10') // Roof tile accessories
				accPallets += (art.total / await this.selection.getPalletAmount(art.artnr)) || 0;
		}

		let sum = Math.ceil(tilePallets);
		if (accPallets >= 0.25)
			sum += Math.ceil(accPallets);

		return sum;
	}

	public async sumArticles() {
		const sum = {} as { [key: string]: number };

		for (const part of [this.mainPart, ...this.extensions]) {
			addArticleSum(sum, part?.calculateArticles() ?? {});
		}

		// Row 171, Subtract 1 rooftile for every track tile calculated in safe
		const safeRows = await this.safe?.calculateSAFE?.();
		if (safeRows) {
			safeRows.filter(row => [RoofArticle.SpårPanna, RoofArticle.SpårPannaBörjan, RoofArticle.SpårPannaSlut,
					RoofArticle.SpårPannaHöger, RoofArticle.SpårPannaVänster].includes(row.art))
				.forEach(row => sum[RoofArticle.TakPanna] -= row.total);
		}

		return sum;
	}

	/** Multiply `amount` with the `svinn` factor and round up to the next whole `wholePack` (defaults to individual articles). */
	public roundUpArticle(amount: number, svinn = 1.00, wholePack = 1) {
		return Math.ceil(amount * svinn / wholePack) * wholePack;
	}

	/** Multiply `amount` with the `svinn` factor and round up to the next whole `wholePack`. Used for compatibility with old calc, can be replaced with roundUpArticle in the future */
	public svinn(amount: number, svinn: number) {
		if (isCompatMode())
			// Bug in old calc where input parameter is int-typed when it can take float values, meaning values will be bankers rounded to an int, possibly downwards
			return Math.ceil(round(amount) * svinn);

		return Math.ceil(amount * svinn);
	}

	public async addArticleRow(sum: ResultRow[], roofArticle: RoofArticle, amount: number, category: Category, isAccessory = false) {
		if (amount <= 0) return;

		if (isCompatMode()) {
			// Simulate apparent bug in old roofcalc, it seems the old calc only saves the most recent RoofArticle amount to resolve to a
			// certain article number meaning that e.g Carisma with NockBörjan and BörjanValm represented by the same artnr will
			// ignore NockBörjan articles if at least 1 BörjanValm has been added
			// TODO: Verify with benders this actually is a bug, TC8 relies on this behaviour
			const row = { art: roofArticle, artnr: await this.selection.getArticleNumber(roofArticle), total: amount, category: category, origins: {} };
			const i = sum.findIndex(r => r.artnr === row.artnr && r.category === row.category);
			if (i >= 0) sum[i] = row;
			else sum.push(row);

		} else {
			if (isAccessory) {
				const accArticle = await this.selection.tileFamily.getAccessoryVariant(roofArticle, { accessoryColor: this.selection.selAccColor });
				await addResult(sum, { art: roofArticle, artnr: accArticle?.artnr ?? '', total: amount, category: category, origins: {} });
			}else {
				// This is the proper way to add article rows
				await addResult(sum, { art: roofArticle, artnr: await this.selection.getArticleNumber(roofArticle), total: amount, category: category, origins: {} });
			}
		}
	}

	public addArticleRowByArtnr(sum: ResultRow[], artnr: string, amount: number, category: Category, origin?: string, art?: RoofArticle) {
		if (amount <= 0) return;
		if ((artnr?.length ?? 0) <= 0) return;

		addResult(sum, { art: art ?? RoofArticle.None, artnr, total: amount, category: category, origins: {} }, origin);
	}

	/** Calculate tiles and produce article rows needed for the current market/tile configuration */
	// Paths:
	// TC1	If STD Nockpanna
	// TC2	Else
	// 	If Gavelpannabred_flg
	// 	Else
	// TC2	If Norge
	// TC1	Else
	// TC1	If STD Nockpanna && !Norge
	// TC2	If Norge
	// TC1	Else
	// TC1	If STD Nockpanna
	// TC2	Else
	public async calculateAll(): Promise<ResultRow[]> {
		if (this.selection.tileFamily === RoofTile.None) throw Error('Tile not set');
		if (this.mainPart === null) throw Error('No main roof part set');

		// Accessory rows are taken from old calc and are not to be used on the new web client. Skip if we're not in unit tests
		const skipAccessories = isCompatMode() == false;

		let results: ResultRow[] = [];
		try {
			const calc = await this.sumArticles();

			// console.log(Object.keys(calc).reduce((acc, val) => ({ ...acc, [RoofArticle[parseInt(val)]]: calc[val] }), {}));

			switch (this.selection.tileFamily.family) {
				case TileFamily.Palema:
					await this.addArticleRow(results, RoofArticle.TakPanna, this.roundUpArticle(calc[RoofArticle.TakPanna], svinn.takpanna, this.selection.tileFamily.perPall / 2), Category.Takpannor);

					if (this.selection.selNockTile == NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.NockPanna, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);

						await this.addArticleRow(results, RoofArticle.NockBörjan, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockSlut, calc[RoofArticle.NockSlut], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlocka, calc[RoofArticle.ValmKlocka], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.BörjanValm, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockTBörjan, calc[RoofArticle.TNock], Category.Takpannor);
						if (!isCompatMode()) await this.addArticleRow(results, RoofArticle.NockXLutande, calc[RoofArticle.XNock], Category.Takpannor);
					} else if (this.selection.selNockTile == NockPannaVariant.Alt) {
						await this.addArticleRow(results, RoofArticle.NockPannaAlt, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);

						await this.addArticleRow(results, RoofArticle.NockBörjanAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockSlutAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlockaBörjan, this.roundUpArticle(calc[RoofArticle.ValmKlocka] / 2), Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlockaSlut, this.roundUpArticle(calc[RoofArticle.ValmKlocka] / 2), Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockBörjanAlt, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockTBörjanAlt, calc[RoofArticle.TNock], Category.Takpannor);
						if (!isCompatMode()) await this.addArticleRow(results, RoofArticle.NockXLutandeAlt, calc[RoofArticle.XNock], Category.Takpannor);
					}
					if (isCompatMode()) await this.addArticleRow(results, RoofArticle.NockXPlan, calc[RoofArticle.XNock], Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.HalvPanna, this.roundUpArticle(calc[RoofArticle.HalvPanna], svinn.halvpanna), Category.Takpannor);

					if (this.usesBredGavelpanna()) {
						await this.addArticleRow(results, RoofArticle.GavelPannaHögerBred, calc[RoofArticle.GavelPannaHöger], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.GavelPannaVänsterBred, calc[RoofArticle.GavelPannaVänster], Category.Takpannor);
					} else {
						await this.addArticleRow(results, RoofArticle.GavelPannaHöger, calc[RoofArticle.GavelPannaHöger], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.GavelPannaVänster, calc[RoofArticle.GavelPannaVänster], Category.Takpannor);
					}
					await this.addArticleRow(results, RoofArticle.GavelBeslag, calc[RoofArticle.GavelBeslag], Category.Takpannor);

					if (skipAccessories) break;

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Snöglidhinder, this.mainPart.calculateSnöglidhinder(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.SnöglidhinderKonsol, this.mainPart.calculateSnöglidhinderKonsoler(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Ventilationshuv2, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare2, 1, Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Kragrör, 1, Category.Tillbehör);
					}

					await this.addArticleRow(results, RoofArticle.Glidskydd, 1, Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Valmtätning300, Math.ceil(this.sumNockOchValmtätningsrulle() / 5000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Ventinock, this.sumVentinock(), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Rändalstätning, Math.ceil(this.sumRänndalstätning() / 1000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Fågelband, Math.ceil(this.sumTakfotslängd() / 1000), Category.Tillbehör);

					if (this.selection.selNockTile == NockPannaVariant.Standard && this.market != MarketStr.Norge)
						await this.addArticleRow(results, RoofArticle.Nocktätningsremsa, calc[RoofArticle.Nocktätningsremsa], Category.Tillbehör);

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Stormclips, Math.ceil(this.sumStormclips(calc[RoofArticle.TakPanna], calc[RoofArticle.HalvPanna], calc[RoofArticle.GavelPannaVänster], calc[RoofArticle.GavelPannaHöger]) / 125), Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.SpikPanna, Math.ceil(this.roundUpArticle(calc[RoofArticle.Fästelement], svinn.fästelement) / 250), Category.Tillbehör);
					}

					if (this.selection.selNockTile == NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.Nockskruv, this.roundUpArticle(calc[RoofArticle.Nockskruv] / 50, svinn.nockskruv), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.NockskruvAlt, this.roundUpArticle(calc[RoofArticle.Nockskruv2] / 50, svinn.nockskruv), Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.NockskruvAlt, this.roundUpArticle(calc[RoofArticle.Nockskruv] / 50, svinn.nockskruv), Category.Tillbehör);
					}
					break;

				case TileFamily.Exklusiv:
					await this.addArticleRow(results, RoofArticle.TakPanna, this.roundUpArticle(calc[RoofArticle.TakPanna], svinn.takpanna, this.selection.tileFamily.perPall / 2), Category.Takpannor);

					if (this.selection.selNockTile == NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.NockPanna, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);

						await this.addArticleRow(results, RoofArticle.NockBörjan, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockSlut, calc[RoofArticle.NockSlut], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.BörjanValm, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockTBörjan, calc[RoofArticle.TNock], Category.Takpannor);
						if (!isCompatMode()) await this.addArticleRow(results, RoofArticle.NockXLutande, calc[RoofArticle.XNock], Category.Takpannor);
					} else if (this.selection.selNockTile == NockPannaVariant.Alt) {
						await this.addArticleRow(results, RoofArticle.NockPannaAlt, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);

						await this.addArticleRow(results, RoofArticle.NockBörjanAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockSlutAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.BörjanValmAlt, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockTBörjanAlt, calc[RoofArticle.TNock], Category.Takpannor);
						if (!isCompatMode()) await this.addArticleRow(results, RoofArticle.NockXLutandeAlt, calc[RoofArticle.XNock], Category.Takpannor);
					}
					if (isCompatMode()) await this.addArticleRow(results, RoofArticle.NockXPlan, calc[RoofArticle.XNock], Category.Takpannor);

					if (this.selection.selNockTile == NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.ValmKlocka, calc[RoofArticle.ValmKlocka], Category.Takpannor);
					} else if (this.selection.selNockTile == NockPannaVariant.Alt) {
						await this.addArticleRow(results, RoofArticle.ValmKlockaBörjan, Math.ceil(calc[RoofArticle.ValmKlocka] / 2), Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlockaSlut, Math.floor(calc[RoofArticle.ValmKlocka] / 2), Category.Takpannor);
					}

					if (this.usesBredGavelpanna() && !isCompatMode()) {
						await this.addArticleRow(results, RoofArticle.GavelPannaVänsterBred, calc[RoofArticle.GavelPannaVänster], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.GavelPannaHögerBred, calc[RoofArticle.GavelPannaHöger], Category.Takpannor);
					} else {
						await this.addArticleRow(results, RoofArticle.GavelPannaVänster, calc[RoofArticle.GavelPannaVänster], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.GavelPannaHöger, calc[RoofArticle.GavelPannaHöger], Category.Takpannor);
					}
					await this.addArticleRow(results, RoofArticle.GavelBeslag, calc[RoofArticle.GavelBeslag], Category.Takpannor);

					if (skipAccessories) break;

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv2, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare2, 1, Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare, 1, Category.Tillbehör);
					}
					await this.addArticleRow(results, RoofArticle.Glidskydd, 1, Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Valmtätning300, Math.ceil(this.sumNockOchValmtätningsrulle() / 5000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Rändalstätning, Math.ceil(this.sumRänndalstätning() / 1000), Category.Tillbehör);

					if (this.market !== MarketStr.Norge && this.selection.selNockTile === NockPannaVariant.Standard)
						await this.addArticleRow(results, RoofArticle.Nocktätningsremsa, calc[RoofArticle.Nocktätningsremsa], Category.Tillbehör);

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Snöglidhinder, this.mainPart.calculateSnöglidhinder(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.SnöglidhinderKonsol, this.mainPart.calculateSnöglidhinderKonsoler(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.InfästTaksteg, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Taksteg, calc[RoofArticle.Taksteg], Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.SpikPanna, Math.ceil(this.roundUpArticle(this.mainPart.calculateGavelbeslag() * 2, svinn.fästelement) / 250), Category.Tillbehör);
					}
					await this.addArticleRow(results, RoofArticle.Stormclips, Math.ceil(this.roundUpArticle(calc[RoofArticle.Fästelement], svinn.fästelement) / 100), Category.Tillbehör);

					if (this.selection.selNockTile == NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.Nockskruv, Math.ceil(this.roundUpArticle(calc[RoofArticle.Nockskruv], svinn.nockskruv) / 50), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.NockskruvAlt, Math.ceil(this.roundUpArticle(calc[RoofArticle.Nockskruv2], svinn.nockskruv) / 50), Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.NockskruvAlt, this.roundUpArticle((calc[RoofArticle.Nockskruv] + calc[RoofArticle.Nockskruv2]) / 50, svinn.nockskruv), Category.Tillbehör);
					}

					await this.addArticleRow(results, RoofArticle.Fågelband, Math.ceil(this.sumTakfotslängd() / 1000), Category.Tillbehör);

					break;

				case TileFamily.Carisma:
					await this.addArticleRow(results, RoofArticle.TakPanna, this.roundUpArticle(calc[RoofArticle.TakPanna], svinn.takpanna, this.selection.tileFamily.perPall / 2), Category.Takpannor);

					if (this.selection.selNockTile == NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.NockPanna, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);

						await this.addArticleRow(results, RoofArticle.NockBörjan, calc[RoofArticle.NockBörjan] + calc[RoofArticle.NockSlut], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlocka, calc[RoofArticle.ValmKlocka], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.BörjanValm, calc[RoofArticle.BörjanValm], Category.Takpannor);
						if (!isCompatMode()) await this.addArticleRow(results, RoofArticle.NockXLutande, calc[RoofArticle.XNock], Category.Takpannor);
					} else if (this.selection.selNockTile == NockPannaVariant.Alt) {
						await this.addArticleRow(results, RoofArticle.NockPannaAlt, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);

						await this.addArticleRow(results, RoofArticle.NockBörjanAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockSlutAlt, calc[RoofArticle.NockSlut], Category.Takpannor);
						// TODO: These articles are never returned by the old calc, is our source old?
						// await this.addArticleRow(results, RoofArticle.ValmKlockaBörjan,	this.roundUpArticle(calc[RoofArticle.ValmKlocka] / 2), Category.Takpannor);
						// await this.addArticleRow(results, RoofArticle.ValmKlockaSlut,	this.roundUpArticle(calc[RoofArticle.ValmKlocka] / 2), Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlockaAlt, calc[RoofArticle.ValmKlocka], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.BörjanValmAlt, calc[RoofArticle.BörjanValm], Category.Takpannor);
						if (!isCompatMode()) await this.addArticleRow(results, RoofArticle.NockXLutandeAlt, calc[RoofArticle.XNock], Category.Takpannor);
					}

					await this.addArticleRow(results, RoofArticle.GavelPannaVänsterBred, calc[RoofArticle.GavelPannaVänster], Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaHögerBred, calc[RoofArticle.GavelPannaHöger], Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaHalvVänsterBred, calc[RoofArticle.GavelPannaHalvVänster], Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaHalvHögerBred, calc[RoofArticle.GavelPannaHalvHöger], Category.Takpannor);

					await this.addArticleRow(results, RoofArticle.GavelBeslag, calc[RoofArticle.GavelBeslag], Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.HalvPanna, this.svinn(calc[RoofArticle.HalvPanna] / 2, svinn.halvpanna), Category.Takpannor);

					if (skipAccessories) break;

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv2, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare2, 1, Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Kragrör, 1, Category.Tillbehör);
					}
					await this.addArticleRow(results, RoofArticle.Glidskydd, 1, Category.Tillbehör);

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Snöglidhinder, this.mainPart.calculateSnöglidhinder(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.SnöglidhinderKonsol, this.mainPart.calculateSnöglidhinderKonsoler(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.InfästTaksteg, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Taksteg, calc[RoofArticle.Taksteg], Category.Tillbehör);
					}
					await this.addArticleRow(results, RoofArticle.Valmtätning300, Math.ceil(this.sumNockOchValmtätningsrulle() / 5000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Rändalstätning, Math.ceil(this.sumRänndalstätning() / 1000), Category.Tillbehör);

					if (this.market == MarketStr.Norge)
						await this.addArticleRow(results, RoofArticle.VenTakfotsläkt, Math.ceil(this.sumTakfotslängd() / 1000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.CBygel, calc[RoofArticle.CBygel], Category.Tillbehör);

					if (this.selection.selGavelTillval !== GavelTillval.Gavelpanna) {
						await this.addArticleRow(results, RoofArticle.Tätningskloss, calc[RoofArticle.Tätningskloss], Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avrinningsplåt, calc[RoofArticle.Avrinningsplåt], Category.Tillbehör);
					}

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Stormclips, Math.ceil(this.roundUpArticle(calc[RoofArticle.Stormclips], svinn.fästelement) / 125), Category.Tillbehör);
					} else if (this.selection.selNockTile !== NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.Nocktätningsremsa, calc[RoofArticle.Nocktätningsremsa], Category.Tillbehör);
					}
					await this.addArticleRow(results, RoofArticle.SkruvPanna, Math.ceil((calc[RoofArticle.GavelBeslag] * 2 + calc[RoofArticle.Fästelement]) * svinn.fästelement / 200), Category.Tillbehör);
					if (this.selection.selNockTile == NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.Nockskruv, this.roundUpArticle(calc[RoofArticle.Nockskruv] / 100, svinn.nockskruv), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.NockskruvAlt, this.roundUpArticle(calc[RoofArticle.Nockskruv2] / 50, svinn.nockskruv), Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.NockskruvAlt, this.roundUpArticle((calc[RoofArticle.Nockskruv] + calc[RoofArticle.Nockskruv2]) / 50, svinn.nockskruv), Category.Tillbehör);
					}

					break;

				case TileFamily.HansaFalsatLertegel:
				case TileFamily.HansaFalsatLertegelRakFramkant:
					await this.addArticleRow(results, RoofArticle.TakPanna, this.roundUpArticle(calc[RoofArticle.TakPanna], svinn.lertegel, this.selection.tileFamily.amountPerPack()), Category.Takpannor);

					if (this.selection.selNockTile == NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.NockPanna, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);
						// BENT-107: Use different tiles for Nockpanna Universal when gavelpanna is selected
						// (NockBörjan&NockSlut should only be used when Gavelpanna is selected so we might've gotten away with changing NockBörjan directly.
						//  Let's keep it like this in case we actually need to use the normal nockbörjan/slut somewhere later)
						const gavel = this.selection.selGavelTillval == GavelTillval.Gavelpanna && !isCompatMode();
						// BENT-179: Use H310 as nockbörjan for norway when main roof has valm shape
						const nockBörjanArt = (!isCompatMode() && this.market == MarketStr.Norge && [RoofForm.Valm, RoofForm.Halvvalm].includes(this.mainPart?.form)) ? RoofArticle.BörjanValm : RoofArticle.NockBörjan;
						await this.addArticleRow(results, gavel ? RoofArticle.NockBörjanGavel : nockBörjanArt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, gavel ? RoofArticle.NockSlutGavel : RoofArticle.NockSlut, calc[RoofArticle.NockSlut], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlocka, calc[RoofArticle.ValmKlocka], Category.Takpannor);

						if (this.market == MarketStr.Norge) {
							await this.addArticleRow(results, RoofArticle.BörjanValm, calc[RoofArticle.BörjanValm], Category.Takpannor);
						} else {
							await this.addArticleRow(results, gavel ? RoofArticle.NockBörjanGavel : nockBörjanArt, calc[RoofArticle.BörjanValm], Category.Takpannor);
						}

						await this.addArticleRow(results, RoofArticle.NockTBörjan, calc[RoofArticle.TNock], Category.Takpannor);
					} else if (this.selection.selNockTile == NockPannaVariant.Alt) {
						await this.addArticleRow(results, RoofArticle.NockPannaAlt, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockBörjanAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockSlutAlt, calc[RoofArticle.NockSlut], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlockaAlt, calc[RoofArticle.ValmKlocka], Category.Takpannor);
						if (isCompatMode()) await this.addArticleRow(results, RoofArticle.BörjanValm, calc[RoofArticle.BörjanValm], Category.Takpannor);
						else await this.addArticleRow(results, RoofArticle.BörjanValmAlt, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockTBörjanAlt, calc[RoofArticle.TNock], Category.Takpannor);
					}

					// Issue tracker -23, row 90, we should not add alt-tile products when the user selected base-tile
					if (isCompatMode()) {
						if (this.selection.selGavelTillval == GavelTillval.Gavelpanna) {
							await this.addArticleRow(results, RoofArticle.NockBörjanAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
							await this.addArticleRow(results, RoofArticle.NockSlutAlt, calc[RoofArticle.NockSlut], Category.Takpannor);
						} else {
							await this.addArticleRow(results, RoofArticle.NockBörjan, calc[RoofArticle.NockBörjan], Category.Takpannor);
							await this.addArticleRow(results, RoofArticle.NockSlut, calc[RoofArticle.NockSlut], Category.Takpannor);
						}
					}

					await this.addArticleRow(results, RoofArticle.NockAnslutning, calc[RoofArticle.NockAnslutning], Category.Takpannor);

					await this.addArticleRow(results, RoofArticle.GavelPannaNAHöger, Math.ceil(calc[RoofArticle.NockAnslutningsGavelPanna] / 2), Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaNAVänster, Math.ceil(calc[RoofArticle.NockAnslutningsGavelPanna] / 2), Category.Takpannor);

					await this.addArticleRow(results, RoofArticle.GavelPannaVänsterBred, calc[RoofArticle.GavelPannaVänster], Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaHögerBred, calc[RoofArticle.GavelPannaHöger], Category.Takpannor);

					if (skipAccessories) break;

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv2, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare2, 1, Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare2, 1, Category.Tillbehör);
					}
					await this.addArticleRow(results, RoofArticle.Glidskydd, 1, Category.Tillbehör);

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Snöglidhinder, this.mainPart.calculateSnöglidhinder(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.SnöglidhinderKonsol, this.mainPart.calculateSnöglidhinderKonsoler(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.InfästTaksteg, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Taksteg, calc[RoofArticle.Taksteg], Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Ventilationshuv2, 1, Category.Tillbehör);
					}

					await this.addArticleRow(results, RoofArticle.Valmtätning300, Math.ceil(this.sumNockOchValmtätningsrulle() / 5000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Rändalstätning, Math.ceil(this.sumRänndalstätning() / 1000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Fågelband, Math.ceil(this.sumTakfotslängd() / 1000), Category.Tillbehör);

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Stormclips, Math.ceil(this.sumStormclips(calc[RoofArticle.TakPanna], calc[RoofArticle.HalvPanna], calc[RoofArticle.GavelPannaVänster], calc[RoofArticle.GavelPannaHöger]) / 125), Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.Nocktätningsremsa, calc[RoofArticle.Nocktätningsremsa], Category.Tillbehör);
						if (this.underlagstak === Underlagstak.RåspontMedPapp) {
							await this.addArticleRow(results, RoofArticle.EasyClip38, Math.ceil(this.roundUpArticle(calc[RoofArticle.Fästelement], svinn.fästelement) / 250), Category.Tillbehör);
						} else if ([Underlagstak.LättMedStröläkt, Underlagstak.LättUtanStröläkt].includes(this.underlagstak)) {
							await this.addArticleRow(results, RoofArticle.EasyClip50, Math.ceil(this.roundUpArticle(calc[RoofArticle.Fästelement], svinn.fästelement) / 250), Category.Tillbehör);
						}
					}

					await this.addArticleRow(results, RoofArticle.Nockskruv, this.roundUpArticle(calc[RoofArticle.Nockskruv] / 50, svinn.nockskruv), Category.Tillbehör);
					if (this.selection.selNockTile == NockPannaVariant.Standard)
						await this.addArticleRow(results, RoofArticle.Nockklammer, this.roundUpArticle(calc[RoofArticle.Nockskruv], svinn.nockklämma), Category.Tillbehör);
					else if (this.selection.selNockTile == NockPannaVariant.Alt)
						await this.addArticleRow(results, RoofArticle.NockklammerAlt, this.roundUpArticle(calc[RoofArticle.Nockskruv], svinn.nockklämma), Category.Tillbehör);

					break;

				case TileFamily.TvillingFalsatLertegel:
					await this.addArticleRow(results, RoofArticle.TakPanna, this.roundUpArticle(calc[RoofArticle.TakPanna], svinn.lertegel, this.selection.tileFamily.amountPerPack()), Category.Takpannor);

					if (this.selection.selNockTile == NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.NockPanna, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);
						const gavel = this.selection.selGavelTillval == GavelTillval.Gavelpanna && !isCompatMode();
						const nockBörjanArt = (!isCompatMode() && this.market == MarketStr.Norge && [RoofForm.Valm, RoofForm.Halvvalm].includes(this.mainPart?.form)) ? RoofArticle.BörjanValm : RoofArticle.NockBörjan;
						await this.addArticleRow(results, gavel ? RoofArticle.NockBörjanGavel : nockBörjanArt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, gavel ? RoofArticle.NockSlutGavel : RoofArticle.NockSlut, calc[RoofArticle.NockSlut], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlocka, calc[RoofArticle.ValmKlocka], Category.Takpannor);
						await this.addArticleRow(results, nockBörjanArt, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.TNock, calc[RoofArticle.TNock], Category.Takpannor);
					} else if (this.selection.selNockTile == NockPannaVariant.Alt) {
						await this.addArticleRow(results, RoofArticle.NockPannaAlt, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockBörjanAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockSlutAlt, calc[RoofArticle.NockSlut], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlockaAlt, calc[RoofArticle.ValmKlocka], Category.Takpannor);
						if (isCompatMode()) await this.addArticleRow(results, RoofArticle.BörjanValm, calc[RoofArticle.BörjanValm], Category.Takpannor);
						else await this.addArticleRow(results, RoofArticle.BörjanValmAlt, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.TNockAlt, calc[RoofArticle.TNock], Category.Takpannor);
					}

					// Issue tracker -23, row 90, we should not add alt-tile products when the user selected base-tile
					if (isCompatMode()) {
						if (this.selection.selGavelTillval === GavelTillval.Gavelpanna) {
							await this.addArticleRow(results, RoofArticle.NockBörjanAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
							await this.addArticleRow(results, RoofArticle.NockSlutAlt, calc[RoofArticle.NockSlut], Category.Takpannor);
						} else {
							await this.addArticleRow(results, RoofArticle.NockBörjan, calc[RoofArticle.NockBörjan], Category.Takpannor);
							await this.addArticleRow(results, RoofArticle.NockSlut, calc[RoofArticle.NockSlut], Category.Takpannor);
						}
					}

					if (isCompatMode()) // We've already added this row, bug from old calc
						await this.addArticleRow(results, RoofArticle.ValmKlocka, calc[RoofArticle.ValmKlocka], Category.Takpannor);

					await this.addArticleRow(results, RoofArticle.HalvPanna, this.roundUpArticle(calc[RoofArticle.HalvPanna], svinn.halvpanna), Category.Takpannor);

					await this.addArticleRow(results, RoofArticle.NockAnslutning, calc[RoofArticle.NockAnslutning], Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaNAHöger, Math.ceil(calc[RoofArticle.NockAnslutningsGavelPanna] / 2), Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaNAVänster, Math.ceil(calc[RoofArticle.NockAnslutningsGavelPanna] / 2), Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaVänsterBred, calc[RoofArticle.GavelPannaVänster], Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaHögerBred, calc[RoofArticle.GavelPannaHöger], Category.Takpannor);

					if (skipAccessories) break;

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv2, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare2, 1, Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare, 1, Category.Tillbehör);
					}

					await this.addArticleRow(results, RoofArticle.Glidskydd, 1, Category.Tillbehör);
					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Snöglidhinder, this.mainPart.calculateSnöglidhinder(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.SnöglidhinderKonsol, this.mainPart.calculateSnöglidhinderKonsoler(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.InfästTaksteg, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Taksteg, calc[RoofArticle.Taksteg], Category.Tillbehör);
					}

					await this.addArticleRow(results, RoofArticle.Valmtätning300, Math.ceil(this.sumNockOchValmtätningsrulle() / 5000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Rändalstätning, Math.ceil(this.sumRänndalstätning() / 1000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Fågelband, Math.ceil(this.sumTakfotslängd() / 1000), Category.Tillbehör);

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Stormclips, Math.ceil(this.sumStormclips(calc[RoofArticle.TakPanna], calc[RoofArticle.HalvPanna], calc[RoofArticle.GavelPannaVänster], calc[RoofArticle.GavelPannaHöger]) / 125), Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.Nocktätningsremsa, calc[RoofArticle.Nocktätningsremsa], Category.Tillbehör);
						if (this.underlagstak === Underlagstak.RåspontMedPapp) {
							await this.addArticleRow(results, RoofArticle.EasyClip38, Math.ceil(this.svinn(calc[RoofArticle.Fästelement], svinn.fästelement) / 250), Category.Tillbehör);
						} else if ([Underlagstak.LättMedStröläkt, Underlagstak.LättUtanStröläkt].includes(this.underlagstak)) {
							await this.addArticleRow(results, RoofArticle.EasyClip50, Math.ceil(this.roundUpArticle(calc[RoofArticle.Fästelement], svinn.fästelement) / 250), Category.Tillbehör);
						}
					}

					await this.addArticleRow(results, RoofArticle.Nockskruv, this.roundUpArticle(calc[RoofArticle.Nockskruv] / 50, svinn.nockskruv), Category.Tillbehör);
					if (this.selection.selNockTile == NockPannaVariant.Standard)
						await this.addArticleRow(results, RoofArticle.Nockklammer, this.roundUpArticle(calc[RoofArticle.Nockskruv], svinn.nockklämma), Category.Tillbehör);
					else if (this.selection.selNockTile == NockPannaVariant.Alt)
						await this.addArticleRow(results, RoofArticle.NockklammerAlt, this.roundUpArticle(calc[RoofArticle.Nockskruv], svinn.nockklämma), Category.Tillbehör);

					break;

				case TileFamily.TvillingLertegelRakFramkant:
					await this.addArticleRow(results, RoofArticle.TakPanna, this.roundUpArticle(calc[RoofArticle.TakPanna], svinn.lertegel, this.selection.tileFamily.amountPerPack()), Category.Takpannor);

					if (this.selection.selNockTile == NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.NockPanna, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);
						const gavel = this.selection.selGavelTillval == GavelTillval.Gavelpanna && !isCompatMode();
						const nockBörjanArt = (!isCompatMode() && this.market == MarketStr.Norge && [RoofForm.Valm, RoofForm.Halvvalm].includes(this.mainPart?.form)) ? RoofArticle.BörjanValm : RoofArticle.NockBörjan;
						await this.addArticleRow(results, gavel ? RoofArticle.NockBörjanGavel : nockBörjanArt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, gavel ? RoofArticle.NockSlutGavel : RoofArticle.NockSlut, calc[RoofArticle.NockSlut], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlocka, calc[RoofArticle.ValmKlocka], Category.Takpannor);
						await this.addArticleRow(results, nockBörjanArt, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.TNock, calc[RoofArticle.TNock], Category.Takpannor);
					} else if (this.selection.selNockTile == NockPannaVariant.Alt) {
						await this.addArticleRow(results, RoofArticle.NockPannaAlt, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockBörjanAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockSlutAlt, calc[RoofArticle.NockSlut], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlockaAlt, calc[RoofArticle.ValmKlocka], Category.Takpannor);
						if (isCompatMode()) await this.addArticleRow(results, RoofArticle.NockBörjan, calc[RoofArticle.BörjanValm], Category.Takpannor);
						else await this.addArticleRow(results, RoofArticle.BörjanValmAlt, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.TNockAlt, calc[RoofArticle.TNock], Category.Takpannor);
					}

					await this.addArticleRow(results, RoofArticle.HalvPanna, this.roundUpArticle(calc[RoofArticle.HalvPanna], svinn.halvpanna), Category.Takpannor);

					await this.addArticleRow(results, RoofArticle.NockAnslutning, calc[RoofArticle.NockAnslutning], Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaNAHöger, Math.ceil(calc[RoofArticle.NockAnslutningsGavelPanna] / 2), Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaNAVänster, Math.ceil(calc[RoofArticle.NockAnslutningsGavelPanna] / 2), Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaVänsterBred, calc[RoofArticle.GavelPannaVänster], Category.Takpannor);
					await this.addArticleRow(results, RoofArticle.GavelPannaHögerBred, calc[RoofArticle.GavelPannaHöger], Category.Takpannor);

					if (skipAccessories) break;

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv2, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare2, 1, Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare, 1, Category.Tillbehör);
					}

					await this.addArticleRow(results, RoofArticle.Glidskydd, 1, Category.Tillbehör);
					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Snöglidhinder, this.mainPart.calculateSnöglidhinder(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.SnöglidhinderKonsol, this.mainPart.calculateSnöglidhinderKonsoler(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.InfästTaksteg, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Taksteg, calc[RoofArticle.Taksteg], Category.Tillbehör);
					}

					await this.addArticleRow(results, RoofArticle.Valmtätning300, Math.ceil(this.sumNockOchValmtätningsrulle() / 5000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Rändalstätning, Math.ceil(this.sumRänndalstätning() / 1000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Fågelband, Math.ceil(this.sumTakfotslängd() / 1000), Category.Tillbehör);

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Stormclips, Math.ceil(this.sumStormclips(calc[RoofArticle.TakPanna], calc[RoofArticle.HalvPanna], calc[RoofArticle.GavelPannaVänster], calc[RoofArticle.GavelPannaHöger]) / 125), Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.Nocktätningsremsa, calc[RoofArticle.Nocktätningsremsa], Category.Tillbehör);
						if (this.underlagstak === Underlagstak.RåspontMedPapp) {
							await this.addArticleRow(results, RoofArticle.EasyClip38, Math.ceil(this.svinn(calc[RoofArticle.Fästelement], svinn.fästelement) / 250), Category.Tillbehör);
						} else if ([Underlagstak.LättMedStröläkt, Underlagstak.LättUtanStröläkt].includes(this.underlagstak)) {
							await this.addArticleRow(results, RoofArticle.EasyClip50, Math.ceil(this.roundUpArticle(calc[RoofArticle.Fästelement], svinn.fästelement) / 250), Category.Tillbehör);
						}
					}

					await this.addArticleRow(results, RoofArticle.Nockskruv, this.roundUpArticle(calc[RoofArticle.Nockskruv] / 50, svinn.nockskruv), Category.Tillbehör);
					if (this.selection.selNockTile == NockPannaVariant.Standard)
						await this.addArticleRow(results, RoofArticle.Nockklammer, this.roundUpArticle(calc[RoofArticle.Nockskruv], svinn.nockklämma), Category.Tillbehör);
					else if (this.selection.selNockTile == NockPannaVariant.Alt)
						await this.addArticleRow(results, RoofArticle.NockklammerAlt, this.roundUpArticle(calc[RoofArticle.Nockskruv], svinn.nockklämma), Category.Tillbehör);

					break;

				case TileFamily.PianoFalsatLertegel:
					await this.addArticleRow(results, RoofArticle.TakPanna, this.roundUpArticle(calc[RoofArticle.TakPanna], svinn.lertegel, this.selection.tileFamily.amountPerPack()), Category.Takpannor);
					if (this.selection.selNockTile == NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.NockPanna, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);
						const nockBörjanArt = (!isCompatMode() && this.market == MarketStr.Norge && [RoofForm.Valm, RoofForm.Halvvalm].includes(this.mainPart?.form)) ? RoofArticle.BörjanValm : RoofArticle.NockBörjan;
						await this.addArticleRow(results, nockBörjanArt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockSlut, calc[RoofArticle.NockSlut], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlocka, calc[RoofArticle.ValmKlocka], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.BörjanValm, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.TNock, calc[RoofArticle.TNock], Category.Takpannor);
					} else if (this.selection.selNockTile == NockPannaVariant.Alt) {
						await this.addArticleRow(results, RoofArticle.NockPannaAlt, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);
						const gavel = this.selection.selGavelTillval == GavelTillval.Gavelpanna && !isCompatMode();
						await this.addArticleRow(results, gavel ? RoofArticle.NockBörjanGavel : RoofArticle.NockBörjanAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, gavel ? RoofArticle.NockSlutGavel : RoofArticle.NockSlutAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlockaAlt, calc[RoofArticle.ValmKlocka], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.BörjanValmAlt, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.TNockAlt, calc[RoofArticle.TNockAlt], Category.Takpannor);
					}
					await this.addArticleRow(results, RoofArticle.HalvPanna, this.roundUpArticle(calc[RoofArticle.HalvPanna], svinn.halvpanna), Category.Takpannor);
					if (!isCompatMode()) {
						await this.addArticleRow(results, RoofArticle.GavelPannaVänsterBred, calc[RoofArticle.GavelPannaVänster], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.GavelPannaHögerBred, calc[RoofArticle.GavelPannaHöger], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.GavelPannaHalvVänsterBred, calc[RoofArticle.GavelPannaHalvVänster], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.GavelPannaHalvHögerBred, calc[RoofArticle.GavelPannaHalvHöger], Category.Takpannor);

						// BENT-108: Inkludera extra-artiklar för varje H423xx och H325xx
						await this.addArticleRow(results, RoofArticle.GavelPannaDubbelfals, results.find(row => row.artnr.startsWith('H423'))?.total ?? 0, Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockBörjanDubbelfals, results.find(row => row.artnr.startsWith('H325'))?.total ?? 0, Category.Takpannor);
					}

					if (skipAccessories) break;

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv2, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avluftare2, 1, Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.Ventilationshuv, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.AvluftareLertegel, 1, Category.Tillbehör);
					}
					await this.addArticleRow(results, RoofArticle.Glidskydd, 1, Category.Tillbehör);

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Snöglidhinder, this.mainPart.calculateSnöglidhinder(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.SnöglidhinderKonsol, this.mainPart.calculateSnöglidhinderKonsoler(), Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.InfästTaksteg, 1, Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Taksteg, calc[RoofArticle.Taksteg], Category.Tillbehör);
					}
					await this.addArticleRow(results, RoofArticle.Valmtätning300, Math.ceil(this.sumNockOchValmtätningsrulle() / 5000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Rändalstätning, Math.ceil(this.sumRänndalstätning() / 1000), Category.Tillbehör);

					if (this.selection.selGavelTillval !== GavelTillval.Gavelpanna) {
						await this.addArticleRow(results, RoofArticle.Tätningskloss, calc[RoofArticle.Tätningskloss], Category.Tillbehör);
						await this.addArticleRow(results, RoofArticle.Avrinningsplåt, calc[RoofArticle.Avrinningsplåt], Category.Tillbehör);
					}

					if (this.market == MarketStr.Norge) {
						await this.addArticleRow(results, RoofArticle.Stormclips, Math.ceil(this.sumStormclips(calc[RoofArticle.TakPanna], calc[RoofArticle.HalvPanna], calc[RoofArticle.GavelPannaVänster], calc[RoofArticle.GavelPannaHöger]) / 125), Category.Tillbehör);
					} else {
						await this.addArticleRow(results, RoofArticle.Nocktätningsremsa, calc[RoofArticle.Nocktätningsremsa], Category.Tillbehör);
						if (this.underlagstak === Underlagstak.RåspontMedPapp) {
							await this.addArticleRow(results, RoofArticle.EasyClip38, Math.ceil(this.roundUpArticle(calc[RoofArticle.Fästelement], svinn.fästelement) / 250), Category.Tillbehör);
						} else if ([Underlagstak.LättMedStröläkt, Underlagstak.LättUtanStröläkt].includes(this.underlagstak)) {
							await this.addArticleRow(results, RoofArticle.EasyClip50, Math.ceil(this.roundUpArticle(calc[RoofArticle.Fästelement], svinn.fästelement) / 250), Category.Tillbehör);
						}
					}
					await this.addArticleRow(results, RoofArticle.Nockskruv, this.roundUpArticle(calc[RoofArticle.Nockskruv] / 50, svinn.nockskruv), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Nockklammer, this.roundUpArticle(calc[RoofArticle.Nockskruv], svinn.nockklämma), Category.Tillbehör);

					break;

				case TileFamily.Strängpressat2kupLertegel:
					await this.addArticleRow(results, RoofArticle.TakPanna, this.roundUpArticle(calc[RoofArticle.TakPanna], svinn.lertegel, this.selection.tileFamily.amountPerPack()), Category.Takpannor);

					if (this.selection.selNockTile == NockPannaVariant.Standard) {
						await this.addArticleRow(results, RoofArticle.NockPanna, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);
						const gavel = this.selection.selGavelTillval == GavelTillval.Gavelpanna && !isCompatMode();
						const nockBörjanArt = (!isCompatMode() && this.market == MarketStr.Norge && [RoofForm.Valm, RoofForm.Halvvalm].includes(this.mainPart?.form)) ? RoofArticle.BörjanValm : RoofArticle.NockBörjan;
						await this.addArticleRow(results, gavel ? RoofArticle.NockBörjanGavel : nockBörjanArt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, gavel ? RoofArticle.NockSlutGavel : RoofArticle.NockSlut, calc[RoofArticle.NockSlut], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlocka, calc[RoofArticle.ValmKlocka], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.BörjanValm, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockTBörjan, calc[RoofArticle.TNock], Category.Takpannor);
					} else if (this.selection.selNockTile == NockPannaVariant.Alt) {
						await this.addArticleRow(results, RoofArticle.NockPannaAlt, this.roundUpArticle(calc[RoofArticle.NockPanna], svinn.nockpanna, 4), Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockBörjanAlt, calc[RoofArticle.NockBörjan], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockSlutAlt, calc[RoofArticle.NockSlut], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.ValmKlockaAlt, calc[RoofArticle.ValmKlocka], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.BörjanValmAlt, calc[RoofArticle.BörjanValm], Category.Takpannor);
						await this.addArticleRow(results, RoofArticle.NockTBörjanAlt, calc[RoofArticle.TNock], Category.Takpannor);
					}

					if (skipAccessories) break;

					await this.addArticleRow(results, RoofArticle.Ventilationshuv, 1, Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.AvluftarePlåt, 1, Category.Tillbehör);

					await this.addArticleRow(results, RoofArticle.Glidskydd, 1, Category.Tillbehör);

					await this.addArticleRow(results, RoofArticle.Valmtätning300, Math.ceil(this.sumNockOchValmtätningsrulle() / 5000), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Fågelband, Math.ceil(this.sumTakfotslängd() / 1000), Category.Tillbehör);

					await this.addArticleRow(results, RoofArticle.Nockskruv, this.roundUpArticle(calc[RoofArticle.Nockskruv] / 50, svinn.nockskruv), Category.Tillbehör);
					if (this.selection.selNockTile == NockPannaVariant.Standard)
						await this.addArticleRow(results, RoofArticle.Nockklammer, this.roundUpArticle(calc[RoofArticle.Nockskruv], svinn.nockklämma), Category.Tillbehör);
					else if (this.selection.selNockTile == NockPannaVariant.Alt)
						await this.addArticleRow(results, RoofArticle.NockklammerAlt, this.roundUpArticle(calc[RoofArticle.Nockskruv], svinn.nockklämma), Category.Tillbehör);
					await this.addArticleRow(results, RoofArticle.Takpannekrok, this.roundUpArticle(calc[RoofArticle.Fästelement] / 100, svinn.fästelement), Category.Tillbehör);

					break;

			}

			// Skip old calcs accessory calculations and read accessory amounts from frontend
			if (skipAccessories) {
				// Add accessory articles that shouldn't have been skipped in the above switch statements
				await this.addArticleRow(results, RoofArticle.Rändalstätning, Math.ceil(this.sumRänndalstätning() / 1000), Category.Tillbehör, true);

				// Nocktätningsremsa beräknas när ventinock är valt
				if (this.selection.selectedSealantArtNo.startsWith('0902')) {
					await this.addArticleRow(results, RoofArticle.Nocktätningsremsa, calc[RoofArticle.Nocktätningsremsa], Category.Tillbehör);
				}

				// Add user selected accessories
				for (const drainAerator of this.selection.selectedDrainAeratorArtNos)
					this.addArticleRowByArtnr(results, drainAerator, this.selection.selectedDrainAeratorAmount * this.amount, Category.Tillbehör);

				for (const ventilationHood of this.selection.selectedVentilationHoodArtNos)
					this.addArticleRowByArtnr(results, ventilationHood, this.selection.selectedVentilationHoodAmount * this.amount, Category.Tillbehör);

				results = addResults(results, await this.sumSealant());

				if ([RoofTile.Carisma].includes(this.selection.tileFamily) && this.selection.selNockTile == NockPannaVariant.Standard
						&& this.selection.selOverlapCarisma == false) {
					await this.addArticleRow(results, RoofArticle.CBygel, calc[RoofArticle.CBygel], Category.Tillbehör, true);
				}

				if ([RoofTile.Carisma, RoofTile.Piano].includes(this.selection.tileFamily)) {
					if (this.selection.selGavelTillval !== GavelTillval.Gavelpanna) {
						// Revert BEN-105 but keep the if statements so we can enable it easily
						// if (this.selection.selDoldAvrinningsplat)
							await this.addArticleRow(results, RoofArticle.Avrinningsplåt, calc[RoofArticle.Avrinningsplåt], Category.Tillbehör, true);
						// if (this.selection.selTatningskloss)
							await this.addArticleRow(results, RoofArticle.Tätningskloss, calc[RoofArticle.Tätningskloss], Category.Tillbehör, true);
					}
				}

				this.addArticleRowByArtnr(results, this.selection.selectedVentilationArtNo, this.sumVentilation(), Category.Tillbehör);

				this.addArticleRowByArtnr(results, this.selection.selectedFixingTileArtNo, this.sumFixingTile(calc), Category.Tillbehör, undefined, RoofArticle.Fästelement);
				this.addArticleRowByArtnr(results, this.selection.selectedFixingTileArtNo, this.selection.extraFixingTiles, Category.Tillbehör, undefined, RoofArticle.Fästelement);
				for (const fixingNock of this.selection.selectedFixingNockArtNos)
					this.addArticleRowByArtnr(results, fixingNock, this.sumFixingNock(calc, fixingNock), Category.Tillbehör);

				if (this.selection.selBTS)
					this.addArticleRow(results, RoofArticle.BTSUnderlagstak, this.sumBTSUndertak(), Category.Tillval);

				// Lägg till en nockbrädshållare på alla tak
				if (this.selection.selNockBoard) await this.addArticleRow(results, RoofArticle.Nockbrädshållare, this.sumNockbrädshållare(), Category.Tillbehör);
			}

			await this.addArticleRow(results, RoofArticle.ItemPallets, await this.calculatePallets(results), Category.Emballage);
		} catch (e) {
			console.error("Error in calculateAll: " + e);
		}

		// Remove empty result rows
		removeZeroQtyRows(results);

		return results;
	}

	public async calculateExtras(extras: Extras): Promise<ResultRow[]> {
		const results: ResultRow[] = [];

		try {
			for (const key in extras.articles) {
				const extra = extras.articles[key];
				this.addArticleRowByArtnr(results, extra.artnr, extra.total * this.amount, Category.Tillval);
			}
		} catch (e) {
			console.error("Error in calculateExtras: " + e);
		}

		// Remove empty result rows
		removeZeroQtyRows(results);

		return results;
	}

	public calculateMainTile(calc: {[key: string]: number}): TotalTiles {
		const mainTile = calc[RoofArticle.TakPanna];

		switch (this.selection.tileFamily.family) {
		case TileFamily.Palema:
		case TileFamily.Exklusiv:
		case TileFamily.Carisma:
			return {
				total: mainTile,
				totalSvinn: this.roundUpArticle(mainTile, svinn.takpanna),
				totalRounded: this.roundUpArticle(mainTile, svinn.takpanna, this.selection.tileFamily.perPall / 2),
				tilePacking: 'p'
			}
		case TileFamily.HansaFalsatLertegel:
		case TileFamily.HansaFalsatLertegelRakFramkant:
		case TileFamily.TvillingFalsatLertegel:
		case TileFamily.TvillingLertegelRakFramkant:
		case TileFamily.PianoFalsatLertegel:
		case TileFamily.Strängpressat2kupLertegel:
			return {
				total: mainTile,
				totalSvinn: this.roundUpArticle(mainTile, svinn.lertegel),
				totalRounded: this.roundUpArticle(mainTile, svinn.lertegel, this.selection.tileFamily.amountPerPack()),
				tilePacking: 'bnt'
			}
		}
		return {
			total: mainTile, totalSvinn: mainTile, totalRounded: mainTile, tilePacking: 'p'
		}
	}

}
