import type { RoofTile } from '@/calculation/rooftiles/rooftile';
import { OptionsBuilder } from '@/calculation/rooftiles/options';
import { TileFamily, RoofArticle, MarketStr, Underlagstak } from '@/calculation/common';
import { FixingsTile } from '@/enums';

export class FixingsTileOptionsBuilder extends OptionsBuilder {
  constructor(rooftile: RoofTile, market: MarketStr) {
    super(rooftile, market, {
      [FixingsTile.EasyClip]: RoofArticle.EasyClip,
      [FixingsTile.Screw]: RoofArticle.SkruvPanna,
      [FixingsTile.Nail]: RoofArticle.SpikPanna,
      [FixingsTile.Stormclips]: RoofArticle.Stormclips,
      [FixingsTile.DiagonalClip]: RoofArticle.DiagonalClip,
      [FixingsTile.DiagonalClipZial]: RoofArticle.DiagonalClipZial,
      [FixingsTile.SidefoldStaples]: RoofArticle.SidofallsKlammer,
      [FixingsTile.RoofhookJP]: RoofArticle.TakkrokJP,
      [FixingsTile.SBracket]: RoofArticle.SKlammer,
      [FixingsTile.RooftileHook]: RoofArticle.Takpannekrok,
    });
  }

  underlagstak(underlagstak: Underlagstak): FixingsTileOptionsBuilder {
    switch (underlagstak) {
      case Underlagstak.RåspontMedPapp:
        this.remove(FixingsTile.DiagonalClip, this.market === MarketStr.Norge ? FixingsTile.EasyClip : null);
        break;
      default:
        this.remove(FixingsTile.DiagonalClipZial, FixingsTile.EasyClip);
    }
    return this;
  }

  family(family: TileFamily): FixingsTileOptionsBuilder {
    switch (family) {
      case TileFamily.Palema:
        this.keep(FixingsTile.EasyClip, FixingsTile.Screw, FixingsTile.Nail, FixingsTile.Stormclips);
        break;
      case TileFamily.Exklusiv:
        this.keep(FixingsTile.EasyClip, FixingsTile.Stormclips);
        break;
      case TileFamily.Carisma:
        this.keep(FixingsTile.Screw, FixingsTile.Stormclips);
        break;
      case TileFamily.PianoFalsatLertegel:
        this.keep(
          FixingsTile.EasyClip,
          this.market !== MarketStr.Norge ? FixingsTile.DiagonalClip : null,
          this.market === MarketStr.Norge ? FixingsTile.SidefoldStaples : null,
        );
        break;
      case TileFamily.HansaFalsatLertegel:
      case TileFamily.HansaFalsatLertegelRakFramkant:
        this.keep(FixingsTile.EasyClip, FixingsTile.DiagonalClip, FixingsTile.DiagonalClipZial, FixingsTile.SidefoldStaples);
        break;
      case TileFamily.TvillingFalsatLertegel:
      case TileFamily.TvillingLertegelRakFramkant:
        this.keep(FixingsTile.EasyClip, this.market !== MarketStr.Norge ? FixingsTile.DiagonalClip : null, FixingsTile.SidefoldStaples);
        break;
      case TileFamily.Strängpressat2kupLertegel:
        this.keep(FixingsTile.RoofhookJP, FixingsTile.SBracket, FixingsTile.RooftileHook);
        break;
    }
    return this;
  }

  setDefault(family: TileFamily, underlagstak: Underlagstak): FixingsTileOptionsBuilder {
    let def = FixingsTile.None;

    switch (this.market) {
      case MarketStr.Norge:
        switch (family) {
          case TileFamily.Palema:
          case TileFamily.Exklusiv:
          case TileFamily.Carisma:
            def = FixingsTile.Stormclips;
            break;
          case TileFamily.HansaFalsatLertegel:
          case TileFamily.HansaFalsatLertegelRakFramkant:
          case TileFamily.TvillingFalsatLertegel:
          case TileFamily.TvillingLertegelRakFramkant:
          case TileFamily.PianoFalsatLertegel:
            def = FixingsTile.SidefoldStaples;
            break;
          case TileFamily.Strängpressat2kupLertegel:
            def = FixingsTile.RoofhookJP;
            break;
        }
        break;
      default:
        switch (family) {
          case TileFamily.Palema:
            if (underlagstak === Underlagstak.RåspontMedPapp) {
              def = FixingsTile.EasyClip;
            } else {
              def = FixingsTile.Screw;
            }
            break;
          case TileFamily.Exklusiv:
            if (underlagstak === Underlagstak.RåspontMedPapp) {
              def = FixingsTile.EasyClip;
            } else {
              def = FixingsTile.Stormclips;
            }
            break;
          case TileFamily.Carisma:
            def = FixingsTile.Screw;
            break;
          case TileFamily.PianoFalsatLertegel:
            if (underlagstak === Underlagstak.RåspontMedPapp) {
              def = FixingsTile.EasyClip;
            } else {
              def = FixingsTile.DiagonalClip;
            }
            break;
          case TileFamily.HansaFalsatLertegel:
          case TileFamily.HansaFalsatLertegelRakFramkant:
          case TileFamily.TvillingFalsatLertegel:
          case TileFamily.TvillingLertegelRakFramkant:
            if (underlagstak === Underlagstak.RåspontMedPapp) {
              def = FixingsTile.EasyClip;
            } else {
              def = FixingsTile.DiagonalClip;
            }
            break;
          case TileFamily.Strängpressat2kupLertegel:
            def = FixingsTile.RoofhookJP;
            break;
        }
    }

    this.default = def;
    return this;
  }
}
