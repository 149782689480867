import type { ProjectData, Roof, Extension, ExtraArticle } from '@/project';
import { ExposedWeather, VentilationHood, SnowSlideObstacle } from '@/enums';
import type { RoofTileOption, SectionValues, RadioOption } from '@/types';
import type { ResultRow, TotalTiles } from '@/calculation/result';
import { Roof as RoofCalc } from '@/calculation/roof';

import { defineStore } from 'pinia';

import { RoofTile } from '@/calculation/rooftile';
import { nanoid } from 'nanoid';
import _ from 'lodash';

import { getList } from '@/api/api';
import { nextTick } from 'vue';
import router from '@/router';

import { defaults } from '@/defaults';
import { getRoofTile } from '@/helpers/tile';
import { getArtHash, getArtPrefix } from '@/helpers/utilities';
import { MarketStr, RoofTypeIndex, RoofArticle } from '@/calculation/common';

// const getDefaultSelections = (n: typeof AccessoryColor | typeof Sealant | typeof Ventilation | typeof ExposedWeather | typeof FixingsTile | typeof FixingsNock | typeof DrainAerator | typeof VentilationHood) => {
//   return Object.values(n).filter((v) => v && typeof v === 'number') as number[];
// }

const calculations = [] as RoofCalc[];
export const useProjectStore = defineStore({
  id: 'project',
  state: () => ({
    id: '',
    shorturl: '',
    step: 1,
    market: MarketStr.Sverige,
    state: '',
    roofIndex: 0,
    extensionIndex: 0,
    loading: {
      fetch: true,
      calc: false,
      calc_initial: true,
    },
    calculated: {
      lathMeasurement: {} as { [key: string]: number[] },
      SAFEDistanceConsole: {} as { [key: string]: number },
      summary: [] as ResultRow[],
      totalTiles: {} as { [key: string]: TotalTiles },
    },
    selections: {
      roof: {
        exposedWeather: [ExposedWeather.Yes, ExposedWeather.No] as ExposedWeather[],
      },
      tile: {
        family: [] as RoofTileOption[],
        coating: [] as RadioOption[],
        color: [] as RadioOption[],
        nock: [] as RadioOption[],
        nockextra: [] as RadioOption[],
        nockconnector: [] as RadioOption[],
        gable: [] as RadioOption[],
      },
      accessory: {
        color: [] as RadioOption[],
        drainAerator: [] as RadioOption[],
        ventilationHood: [VentilationHood.None, VentilationHood.Plate] as VentilationHood[], // Always the same
        sealant: [] as RadioOption[],
        ventilation: [] as RadioOption[],
        concealedDraining: [] as RadioOption[],
        sealingAiring: [] as RadioOption[],
        fixingTile: [] as RadioOption[],
        extraFixingTiles: 0,
        fixingNock: [] as RadioOption[],
      },
      security: {
        battenStep: [] as RadioOption[],
        trackingTiles: [] as RadioOption[],
        snowSlideObstacle: [SnowSlideObstacle.Yes, SnowSlideObstacle.No] as SnowSlideObstacle[],
      },
      extra: {} as SectionValues,
    },
    data: _.cloneDeep(defaults.data) as ProjectData, // Persistent data (Should be typed)
  }),
  getters: {
    calc_tile: (state) => {
      const roof = state.data.roofs[state.roofIndex];
      const roofTile = getRoofTile(roof?.tile?.family);
      if (roofTile === RoofTile.None) console.warn('No calculation available for tile family:', roof?.tile?.family);
      return roofTile;
    },
    getMarket: (state) => state.market,
    activeRoof: (state) => state.data.roofs[state.roofIndex],
    activeExtension: (state) => state.data.roofs[state.roofIndex].extensions[state.extensionIndex],
  },
  actions: {
    getTileCalc(roofIndex: number) {
      const roof = this.data.roofs[roofIndex];
      const roofTile = getRoofTile(roof?.tile?.family);
      if (roofTile === RoofTile.None) console.warn('No calculation available for tile family:', roof?.tile?.family);
      return roofTile;
    },
    setId(id: string | string[]) {
      // if array set first
      if (Array.isArray(id)) {
        this.id = id[0];
      } else {
        this.id = id;
      }
    },
    async fetchData(id: string) {
      if (!id) {
        return;
      } else if (id === 'new') {
        id = '';
      }

      this.loading.fetch = true;
      const list = await getList(this.id);

      if (!list.id) {
        router.push({ name: '500' });
      }

      if (this.data.id !== list.id) {
        // this.setId(list.id);
        router.replace({ name: 'project', params: { id: list.id } });
      }

      this.shorturl = list.shorturl;

      this.setData(
        {
          created: list.created,
          created_by: list?.created_by?.firstName ? `${list.created_by.firstName ?? ''} ${list.created_by.lastName ?? ''}` : '',
          ...list.data,
        } || _.cloneDeep(defaults.data),
      );

      nextTick(() => {
        this.loading.fetch = false;
      });
    },
    setData(data: ProjectData) {
      if (!data) {
        this.data = _.cloneDeep(defaults.data);
        return;
      }

      data = _.merge(this.data, _.merge(_.cloneDeep(defaults.data), data));

      if (this.data?.market) this.market = this.data.market;

      // Add missing roof keys
      for (let i = 0; i < data.roofs.length; i++) {
        data.roofs[i] = _.merge(_.cloneDeep(defaults.roof), data.roofs[i]);
        data.roofs[i].security = _.merge(_.cloneDeep(defaults.roof.security), data.roofs[i].security);
      }

      // if no roofs, add one
      if (!data.roofs.length) this.addNewRoof();

      // Make sure each roof and extension has an id
      for (let i = 0; i < data.roofs.length; i++) {
        if (!data.roofs[i].id) data.roofs[i].id = nanoid();
        for (let j = 0; j < data.roofs[i].extensions.length; j++) {
          if (!data.roofs[i].extensions[j].id) data.roofs[i].extensions[j].id = nanoid();
        }
      }
    },
    setStep(step: number) {
      this.step = Math.max(step, 1);
      this.setRoofIndex(0); // Always reset roof index when changing step
      // for each roof, and extensions stop editing
      for (let i = 0; i < this.data.roofs.length; i++) {
        this.data.roofs[i].editing.roof = false;
        for (let j = 0; j < this.data.roofs[i].extensions.length; j++) {
          this.data.roofs[i].extensions[j].editing.roof = false;
        }
      }
    },
    incrementStep(size = 1) {
      this.setStep(Math.max(this.step + size, 1));
    },
    setRoofIndex(index: number) {
      this.roofIndex = index;

      // if no roofs, add one
      if (this.data.roofs.length === 0) {
        this.addNewRoof();
      }
    },
    getRoofIndexById(id: string) {
      return this.data.roofs.findIndex((r) => r.id === id);
    },
    setActiveRoof(roof: Roof) {
      // Find matching roof in data
      const index = this.data.roofs.findIndex((r) => r.name === roof.name);
      if (index === -1) {
        // If not found, add it
        this.data.roofs.push(roof);
      }
      // Set active roof
      this.roofIndex = index;
    },
    isMansard(roofId: number) {
      const r = this.data.roofs[roofId];
      if (!r) return false;

      return [RoofTypeIndex.Mansard, RoofTypeIndex.MansardValm, RoofTypeIndex.MansardHalvvalm].includes(r.roof.type);
    },
    getRoofMeasurements(roofIndex = -1) {
      const angle = {
        min: 360,
        max: 0,
      };
      let underlagstak = '';
      if (roofIndex === -1) roofIndex = this.roofIndex;
      const roof = this.data.roofs[roofIndex];
      if (roof) {
        for (const r of [roof, ...roof.extensions]) {
          const a = r.roof.dimensions.angle as number;
          const a2 = r.roof.dimensions.angle2 as number;
          const u = r.roof.type as string;
          if (a > 0) {
            if (a > angle.max) angle.max = a;
            if (a < angle.min) angle.min = a;
          }
          // Only mansard roofs use angle 2
          if (this.isMansard(roofIndex) && a2 > 0) {
            if (a2 > angle.max) angle.max = a2;
            if (a2 < angle.min) angle.min = a2;
          }
          if (underlagstak === '') underlagstak = u;
          else if (underlagstak !== u) underlagstak = 'mixed';
        }
      }

      return {
        angle,
        underlagstak,
      };
    },
    getRoofCalc(roofIndex: number): RoofCalc {
      const roof = this.data.roofs[roofIndex];
      const calc = calculations[roofIndex] || new RoofCalc(roof.name, this.market);
      calculations[roofIndex] = calc;
      return calc;
    },
    getRoofCalcById(id: string): RoofCalc | null {
      const roof = this.getRoofById(id);
      if (!roof) return null;

      const index = this.data.roofs.findIndex((r) => r.id === id);
      return this.getRoofCalc(index);
    },
    getRoofById(id: string): Roof | null {
      return this.data.roofs.find((r) => r.id === id) || null;
    },
    addNewRoof() {
      const roof = _.cloneDeep(defaults.roof);
      roof.id = nanoid();
      this.data.roofs.push(roof);
      this.getRoofCalc(this.data.roofs.length - 1); // Set up calculation
    },
    deleteRoof(index: number) {
      this.data.roofs.splice(index, 1);
      calculations.splice(index, 1);
      // if no roofs, add one (We should always have at least one roof)
      if (this.data.roofs.length === 0) {
        this.addNewRoof();
      }
    },
    resetRoof(index: number) {
      if (this.data.roofs.length - 1 < index) return;
      this.data.roofs[index] = _.merge(this.data.roofs[index], _.cloneDeep(defaults.roof));
    },
    setExtensionIndex(index: number) {
      this.extensionIndex = index;

      // if no extension, add one
      if (this.activeRoof.extensions.length === 0) {
        this.addNewExtension();
      }
    },
    addNewExtension() {
      const extension = _.cloneDeep(defaults.roof) as Extension;
      extension.id = nanoid();
      extension.name = 'Ny'; // Don't use default name for extensions

      // Inherit some values from parent roof
      extension.roof.dimensions.lathDistance = this.activeRoof.roof.dimensions.lathDistance;
      extension.roof.dimensions.baseRoof = this.activeRoof.roof.dimensions.baseRoof;
      extension.roof.dimensions.lathMeasurement = this.activeRoof.roof.dimensions.lathMeasurement;

      // Add extension
      this.activeRoof.extensions.push(extension);
    },
    deleteExtension(index: number) {
      this.activeRoof.extensions.splice(index, 1);
    },
    resetExtension(index: number) {
      if (this.activeRoof.extensions.length - 1 < index) return;

      const extension = _.cloneDeep(defaults.roof) as Extension;

      // Inherit some values from parent roof
      extension.roof.dimensions.lathDistance = this.activeRoof.roof.dimensions.lathDistance;
      extension.roof.dimensions.baseRoof = this.activeRoof.roof.dimensions.baseRoof;

      // Update the extension with defaults
      this.activeRoof.extensions[index].type = ''; // Reset extension type as it doesn't exist on the default roof
      this.activeRoof.extensions[index] = _.merge(this.activeRoof.extensions[index], extension);
    },
    setCalculatedAmounts(results: ResultRow[]) {
      this.calculated.summary = results;
    },
    setLathMeasurement(roofId: string, lath1: number, lath2: number) {
      // flatten roofs and their extensions
      const roof = [...this.data.roofs, ...this.data.roofs.reduce((acc, r) => [...acc, ...r.extensions], [] as Extension[])].find((r) => r.id === roofId);
      if (!roof) return;
      this.calculated.lathMeasurement[roofId] = [lath1, lath2];
    },
    setSAFEDistanceConsole(roofId: string, distance: number) {
      // flatten roofs and their extensions
      const roof = [...this.data.roofs, ...this.data.roofs.reduce((acc, r) => [...acc, ...r.extensions], [] as Extension[])].find((r) => r.id === roofId);
      if (!roof) return;
      this.calculated.SAFEDistanceConsole[roofId] = distance;
    },
    setWeight(weight: number) {
      this.data.weight = weight;
    },
    setCalculatedTileCount(totals: { [key: string]: TotalTiles }) {
      this.calculated.totalTiles = totals;
    },
    updateExtraArticle(article: ExtraArticle) {
      // make sure the articles object exists & is an object
      if (!this.activeRoof.extras.articles || typeof this.activeRoof.extras.articles !== 'object') {
        this.activeRoof.extras.articles = {};
      }

      const hash = getArtHash(article.artnr);
      delete this.activeRoof.extras.articles[getArtPrefix(article.artnr)]; // Remove any existing article with the same prefix (Backwards compatibility/cleanup)

      //TODO: Look if this can be optimized while keeping the reactivity
      // if article amount is 0, or nan, remove it
      if (article.amount === 0 || isNaN(article.amount)) {
        delete this.activeRoof.extras.articles[hash];
        // this.activeRoof.extras.articles = this.activeRoof.extras.articles.filter((a) => a && a.artnr !== article.artnr)
      } else {
        // else update it
        this.activeRoof.extras.articles[hash] = article;
        // const index = this.activeRoof.extras.articles.findIndex((a) => a.artnr === article.artnr)
        // if (index === -1) {
        //   this.activeRoof.extras.articles.push(article)
        // } else {
        //   this.activeRoof.extras.articles[index] = article
        // }
      }
    },
    resetRoofValue({ roofIndex, extensionIndex, path }: { roofIndex: number; extensionIndex?: number; path: string }) {
      const value = _.get(defaults, `roof.${path}`, null);
      if (value !== null) {
        if (extensionIndex !== undefined) {
          _.set(this.data.roofs[roofIndex].extensions[extensionIndex], path, value);
        } else {
          _.set(this.data.roofs[roofIndex], path, value);
        }
      }
    },
    waitForCalculation() {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.loading.calc) return;
          clearInterval(interval);
          resolve(true);
        }, 100);
      });
    },
  },
});
